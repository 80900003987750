import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTqyzIxVboEDvW3g5S_zCnGK0smR-jqg8",
  authDomain: "rebootapp-37a30.firebaseapp.com",
  databaseURL: "https://rebootapp-37a30.firebaseio.com",
  projectId: "rebootapp-37a30",
  storageBucket: "rebootapp-37a30.appspot.com",
  messagingSenderId: "364568176835",
  appId: "1:364568176835:web:35401bf96ede6048e31a76",
  measurementId: "G-QQ4CP0F48G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);