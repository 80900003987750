import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    routerSubscription: any;

    constructor(private router: Router) {
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
        });
    }
    
    appLandingNavItem = [
        {
            id: 'home',
            title: 'الرئيسية',  // 'Home',
        },
        {
            id: 'about',
            title: 'عن التطبيق',
        },
        {
            id: 'statistics',
            title: 'إحصائيات التطبيق',
        },
        {
            id: 'faq',
            title: 'الأسئلة الشائعة',
        },
        {
            id: 'feedback',
            title: 'تجارب المستخدمين',
        },
    ];
    
}