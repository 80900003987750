import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-funfacts',
  templateUrl: './app-funfacts.component.html',
  styleUrls: ['./app-funfacts.component.scss'],
})
export class AppFunfactsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  funFacts: Content[] = [
    {
      icon: 'las la-user-circle',
      title: '+ رُبع مليون',
      subTitle: 'مستخدم',
    },
    {
      icon: 'las la-user-check',
      title: '+ 10 آلاف',
      subTitle: 'مستخدم نشط',
    },
    {
      icon: 'las la-cloud-download-alt',
      title: '+450 ألف',
      subTitle: 'تنزيل',
    },
    {
      icon: 'las la-star',
      title: '4.6/5',
      subTitle: 'متوسط التقييمات',
    },
  ];
}
class Content {
  icon: string;
  title: string;
  subTitle: string;
}
