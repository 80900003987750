import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { AppBannerComponent } from './components/pages/app-landing/app-banner/app-banner.component';
import { AppFeaturesComponent } from './components/pages/app-landing/app-features/app-features.component';
import { AppAboutComponent } from './components/pages/app-landing/app-about/app-about.component';
import { AppFunfactsComponent } from './components/pages/app-landing/app-funfacts/app-funfacts.component';
import { AppScreenshotsComponent } from './components/pages/app-landing/app-screenshots/app-screenshots.component';
import { AppPricingComponent } from './components/pages/app-landing/app-pricing/app-pricing.component';
import { AppNewsletterComponent } from './components/pages/app-landing/app-newsletter/app-newsletter.component';
import { AppTeamComponent } from './components/pages/app-landing/app-team/app-team.component';
import { AppFaqComponent } from './components/pages/app-landing/app-faq/app-faq.component';
import { AppFeedbackComponent } from './components/pages/app-landing/app-feedback/app-feedback.component';
import { AppDownloadComponent } from './components/pages/app-landing/app-download/app-download.component';
import { AppHowItWorksComponent } from './components/pages/app-landing/app-how-it-works/app-how-it-works.component';
import { AppContactComponent } from './components/pages/app-landing/app-contact/app-contact.component';
import { FooterComponent } from './components/common/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AppLandingComponent,
    PreloaderComponent,
    AppBannerComponent,
    AppFeaturesComponent,
    AppAboutComponent,
    AppFunfactsComponent,
    AppScreenshotsComponent,
    AppPricingComponent,
    AppNewsletterComponent,
    AppTeamComponent,
    AppFaqComponent,
    AppFeedbackComponent,
    AppDownloadComponent,
    AppHowItWorksComponent,
    AppContactComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
