import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  copyrightText: Text[] = [
    {
      text: 'تطبيق تعافي 2024. جميع الحقوق محفوظة',
    },
  ];
}
class Text {
  text: string;
}
