import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-features',
    templateUrl: './app-features.component.html',
    styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'مميزات التطبيق',
            paragraphText: 'تطبيق تعافي هو التطبيق الافضل لمتابعة رحلتك في التعافي من إدمان الإباحية'
        }
    ]
    singleAppFeatures: FeaturesContent[] = [
        {
            icon: 'las la-cloud-sun',
            title: 'أدوات عملية',
            paragraph: 'الأدوات الموجودة في التطبيق مستلهمة من الحركة العالمية (NoFap) لمساعدتك في رحلتة التعافي من إدمان الإباحية'
        },
        {
            icon: 'las la-shield-alt',
            title: 'بياناتك بأمان',
            paragraph: 'استخدم التطبيق وأنت مرتاح البال! لا يتم الاحتفاظ بأي بيانات تخص هويتك الشخصية بأي شكل من الأشكال'
        },
        {
            icon: 'las la-mobile-alt',
            title: 'iOS & Android',
            paragraph: 'يتوفر التطبيق على منصتي iOS و Android ويمكنك الوصول لحسابك من المنصتين في أي وقت'
        },
        {
            icon: 'las la-book',
            title: 'محتوى متجدد',
            paragraph: 'محتوى متجدد متنوقع يخص التعافي بكل مراحله من البدايات ومرورًا بالتحديات التي تصاحب التعافي وصولا لما بعد التعافي'
        },
        {
            icon: 'las la-lightbulb',
            title: 'تصميم مميز',
            paragraph: 'تصميم أنيق ومميز بواجهات عصرية توفر لك تجربة مميزة أثناء استخدام للتطبيق'
        },
        {
            icon: 'las la-tasks',
            title: 'تحديثات مستمرة',
            paragraph: 'تحديثات دورية للتطبيق لحل المشكلات التي تواجه المستخدمين وإضافة مميزات جديدة تفيد المتعافين'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class FeaturesContent {
    icon : string;
    title : string;
    paragraph : string;
}