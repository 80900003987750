import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-faq',
    templateUrl: './app-faq.component.html',
    styleUrls: ['./app-faq.component.scss']
})
export class AppFaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'الأسئلة الشائعة',
            paragraphText: 'هنا مجموعة من أكثر الأسئلة تكرارًا عن تطبيق تعافي'
        }
    ]
    accordionItem: Content[] = [
        {
            title: 'هل يتم الاحتفاظ ببياناتي الشخصية؟',
            paragraph: "لا يتم الاحتفاظ بأي من البيانات الشخصية إذ يتم تخزين البيانات بصورة مشفرة لا تحتوي على معلومات شخصية"
        },
        {
            title: 'هل التطبيق مجاني بشكل كامل؟',
            paragraph: "نعم! التطبيق بكامل مزاياه - في الفترة الحالية - مجاني بدون أي تكاليف آو رسوم۔ قد تتم إضافة بعد المميزات المدفوعة في المستقبل لدعم تطوير التطبيق"
        },
        {
            title: 'هل أستطيع حذف حسابي؟',
            paragraph: "بكل تأكيد۔ يمكنك حذف حسابك في أي وقت ويمكنك إعادة التسجيل من جديد بنفس البريد الالكتروني"
        },
        {
            title: 'هل يمكنني المشاركة في تطوير التطبيق؟',
            paragraph: "في الفترة الحالية لا توجد حاجة لتوسيع فريق العمل على التطبيق، ولكن يمكنك المشاركة بالآراء والاقتراحات لارتقاء بالتطبيق للأفضل"
        },
        {
            title: 'لماذا لا يوجد محتوى بكمية كبيرة في التطبيق؟',
            paragraph: "الحصول على محتوى جيد، ومناسب عملية تستغرق الكثير من الوقت۔ نرحب دائما بالتوصيات على المحتوى لعرضها على التطبيق"
        },
        {
            title: 'لماذا تتأخر التحديثات في التطبيق؟',
            paragraph: "فريق العمل غير متفرغ بالكامل ويعمل في الأوقات المتاحة بكل جهد ممكن، ودائمًا كل ما كان الوقت متاحًا للعمل على التطبيق سيتم تحديثه ليواكب طموحاتكم"
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Content {
    title : string;
    paragraph : string;
}