<section id="contact" class="contact-area ptb-100">
  <div class="container">
    <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle">
      <h2>{{ sectionTitleContent.title }}</h2>
      <p>{{ sectionTitleContent.paragraphText }}</p>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div
          class="contact-image text-center"
          *ngFor="let Image of contactImage"
        >
          <img [src]="Image.img" alt="image" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="contact-form">
          <div class="row">
            <h1><a href="mailto:admin@ta3afi.app">admin@ta3afi.app</a></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
